import {
  fetchStaticPageData,
  StaticPage,
} from '@dreamstack/accentro-contentful'
import React from 'react'
import { getStaticPropsPlus } from '../lib/next/getStaticPropsPlus'
import { defaultQueries } from '../lib/shared/defaultQueries'

export default function Home() {
  return (
    <>
      <StaticPage slug={'index'} />
    </>
  )
}

export const getStaticProps = getStaticPropsPlus({
  queries: defaultQueries,
  getProps: async ({ apolloClient }) => {
    await fetchStaticPageData({ apolloClient, slug: 'index' })
  },
})
